import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { useStyles } from "../styles";
import Logo from "../components/Logo";
import { Store } from "../Store";
import { Alert } from "@material-ui/lab";
import {
  createOrder,
  setPaymentType,
  generateQrCode,
  clearOrder,
  getCheckoutUrl,
  getUserData,
  setCustomerId,
} from "../actions";
import QRCode from "react-qr-code";
import axios from "axios";
import config, { getParameterByName, merchantCode } from "../Configs";
import BillPrint from "./BillPrint";
import { useIntl } from "react-intl";
import BillSummary from '../components/Bill_Summary';


export default function CompleteOrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { formatMessage: t, locale, setLocale } = useIntl();
  const { order, selectedCurrency, taxPercent, dineinTax,takeAwayTax } = state;
  const { loading, error, newOrder } = state.orderCreate;
  const [custId, setCustId] = useState("");
  // const {customerId } = state.order;
  const [display, setDisplay] = useState(false);
  const [fontColor, setFontColor] = useState();
  const [displayOrder, setDisplayOrder] = useState(false);
  const [invData, setInvData] = useState();
  const [detailview, setDetailView] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [billPrint, setBillPrint] = useState(false);
  let { setting } = state.userSetting;
      
  console.log(setting);
  let { userInfo } = state.userData;
  console.log(userInfo);

  const [phnumber, setPhnumber] = useState("");

  console.log(phnumber);
  let sokURL =
    window.location.href.indexOf("localhost") > 0
      ? "https://sok.menulive.in"
      : window.location.origin;
  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");

  const baseURL = config.baseURL;
  const userCurrency = userInfo ? userInfo.currency : "";
  const isScan = getParameterByName("isScan");

  console.log(isScan);
  const email = userInfo ? userInfo.email : "";
  const upiId = userInfo ? userInfo.paymentProviderCode : "";
  const upiName = userInfo ? userInfo.firstName +" "+ userInfo.lastName : "";
  const address = userInfo? userInfo.address:"";
  const gst = userInfo ? userInfo.taxCodeNo : "";
  const [invoiceNo,setInvoiceNo] = useState("")

  let isPaymentAllowed = setting ? setting.isPaymentAllowed : "";

  let paytimer = null;


  useEffect(() => {
    if(newOrder){
    getInvoice();}
  },[newOrder]);

  console.log(openPhone, order);
  const createNewOrder = (customerId) => {
    if (order.orderItems.length > 0 ) {
      let items = order.orderItems.map((o) => {
        o["status"] = "inProgress";
        o["sub_pro"] = JSON.stringify(o.sub_pro);
        return o;
      });
      order.orderItems = items;
      order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
      order.customerId = "";
      order.userId= merchantCode
      createOrder(dispatch, order);
    }
  };  


  useEffect(() => {
    createNewOrder()
  },[]);


  const getInvoice =() => {
    console.log(newOrder);
    let billData = {};
  billData.userId =merchantCode;
  billData.appName = "SOK";
  billData.payType = "onetime";
  billData.payStatus = "paid";
  billData.purchaseItems = newOrder &&newOrder.orderItems?newOrder.orderItems:"";

  axios
    .post(`${config.payUrl}/api/new-order`, billData)
    .then((res) => {
      setInvoiceNo(res.data.invoiceData.invoicePath);
    });
  }


  useEffect(() => {
    setTimeout(() => {
      if(detailview){
        clearOrder(dispatch);
        props.history.push("/?" + window.location.href.split("?")[1]);
        window.location.reload();
       }
    }, 60*1000);
  
  }, [detailview])

  const handleBack = () => {
    setDisplayOrder(false);
  };
  const handleForm = () => {
    setDisplay(!display);
  };
  const backSpaceImage = () => {
    return (
      <img
        src="/images/backspace-1.png"
        alt="backSpace"
        height="40vh"
        width="40vw"
      />
    );
  };

  let appUrl = window.location.href.replace("complete", "");
  let updateUrl = appUrl.replace(/&/g, "~");
  console.log(updateUrl);

  let orderDetails = newOrder;

  let summaryPath1 = orderDetails
    ? `${window.location.origin}/app.html?serve_url=${baseURL}&orderId=${
        orderDetails ? orderDetails.id : ""
      }&merchantCode=${
        userInfo ? userInfo.merchantCode : ""
      }&currency=${userCurrency}&restaurant=${upiName}&cgst=${gst}&invoice=${
        invoiceNo
      }&sok_url=${updateUrl}`
    : "";

 if(orderDetails&&orderDetails.id){
    orderDetails.orderId=  orderDetails ? orderDetails.id : "";
    orderDetails.merchantCode=  userInfo ? userInfo.merchantCode : "";
    orderDetails.currency= userCurrency;
    orderDetails.restaurant= upiName;
    orderDetails.address= address;
    // address:
    //   userData || merchantData ? merchantData.address || userData.address : "",
    orderDetails.cgst= gst;
    orderDetails.invoice_no= invoiceNo;
  };


  let paymentUrl = orderDetails
    ? `upi://pay?pn=${upiName}&pa=${upiId}&am=${orderDetails.totalPrice}&tn=${orderDetails.number}`
    : "";

  const handleSubmit = () => {
    let msgtext = `${encodeURI(summaryPath1)}`;
    axios
      .post(baseURL.replace("apps", "cms") + "/api/send-sms-msg", {
        toMobile: `+91${phnumber}`,
        msgConent: msgtext,
      })
      .then((res) => {
        setPhnumber("");
      });
  };

  const handlePayment = () => {
    setDetailView(true);
    if (paytimer) {
      clearInterval(paytimer);
    }

    createNewOrder();
    
  };

  const handleCounter = () => {
    if (paytimer) {
      clearInterval(paytimer);
    }
    setDetailView(true);
    createNewOrder();
  
  };

  const orderStauts = () => {
    let id = orderDetails._id;
    axios.get(`${baseURL}/api/orders/${id}`).then((res) => {
      if (res.data.isPaid) {
        if (paytimer) {
          clearInterval(paytimer);
        }
        setDetailView(true);
      }
    });
  };

  // useEffect(() => {
  //   if(PrintInterface && detailview){
  //     setBillPrint(true);
  //   }
  // }, [detailview])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(billPrint){
  //       sessionStorage.removeItem("newOrder");
  //       clearOrder(dispatch);
  //       props.history.push("/?" + window.location.href.split("?")[1]);
  //       setBillPrint(true)
  //      }
  //   }, 5000);
  
  // }, [billPrint])
  
  
  // 


  

  function validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

let PrintInterface = true

  return (
    <Box
      id="paymentroot"
      className={[styles.root1, styles.navy]}
      style={{ backgroundColor: state.selectedBgColor || "white",alignItems:'center' }}
    >

      {detailview && <BillPrint orderDetails={orderDetails} invoiceNo={invoiceNo}/> }

      <Box className={[styles.main1, styles.center]}>
        <Box style={{  padding: "10px" }}>
          { !orderDetails && loading ? (
            <CircularProgress></CircularProgress>
          )  : (
            <>
              <Typography
                gutterBottom
                variant="h3"
                component="h3"
                id="title2"
                style={{ fontSize: "25px" }}
              >
                {detailview || isPaymentAllowed
                  ?  t({ id: "order_placed" })
                  : t({ id: "process_payment" })}
              </Typography>
              

              {!detailview && orderDetails && orderDetails.totalPrice?
              <BillSummary totalPrice={orderDetails.totalPrice} 
              taxPrice={orderDetails.taxPrice}
              t={t}
              />:""}
   

              {false && (detailview || isPaymentAllowed) ? (
                <Typography
                  gutterBottom
                  className={styles.title3}
                  // style={{padding:"50px 100px 0px 0px"}}
                  variant="h3"
                  component="h3"
                >
                  Token #
                  <span className={styles.orderNum}>{orderDetails.number}</span>
                </Typography>
              ) : (
                ""
              )}

              
              {(upiId && isScan !== "true")?
              <h4 style={{ color: "black", fontWeight: "bold",margin:"10px" }}>
                  {!detailview ? t({ id: "scan_to_pay" }) : ""}
              </h4>:""}
             
              {!detailview ? (
                <div>
                  {upiId && isScan !== "true" ? (
                    <QRCode
                      size={456}
                      style={{
                        height: "150px",
                        maxWidth: "150px",
                        width: "150px",
                        marginTop: "20px",
                      }}
                      value={paymentUrl}
                      viewBox={`0 0 456 456`}
                    />
                  ) : (
                    ""
                  )}
                  {upiId && isScan === "true" && !detailview ? (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={paymentUrl}
                        target="_blank"
                        className="upi_btn"
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        UPI Pay
                      </a>
                    </div>
                  ) : (
                    " "
                  )
                  
                }
                {upiId && !detailview && 
                <div style={{ width: "100%" }}>
                <button
                      className="pay-btn"
                      style={{
                        width: "250px",
                      }}
                      onClick={handleCounter}
                    >
                      {t({ id: "payment_done" })}
                    </button>
                    </div>}

                  <div style={{ width: "100%", margin: "15px" }}>
                    <button
                      className="pay-btn"
                      style={{
                        width: "350px",
                        backgroundColor: "#ffbc01",
                        color: "black",
                        fontSize:'1.5em!important',
                        padding:"10px",
                        height:"auto"
                      }}
                      onClick={handleCounter}
                    >
                      {t({ id: "pay_at_counter" })}
                    </button>
                    <br />
                    
                  </div>
                </div>
              ) : (
                ""
              )}

              {detailview || isPaymentAllowed ? (
                <div>
                  {false &&<QRCode
                    size={456}
                    style={{
                      height: "150px",
                      maxWidth: "150px",
                      width: "150px",
                      marginTop: "20px",
                    }}
                    value={encodeURI(summaryPath1)}
                    viewBox={`0 0 456 456`}
                  />}
                
                 
                </div>
              ) : (
                ""
              )}

              {false && isScan === "true" && (detailview || isPaymentAllowed) ? (
                <div>
                  <h6 style={{ marginTop: "15px" }}> ( OR )</h6>
                  <div style={{ marginTop: "20px" }}>
                    <a
                      href={encodeURI(summaryPath1)}
                      target="_blank"
                      style={{
                        fontSize: "18px",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              ) : (
                " "
              )}

              

             
            </>
          )}

          
        </Box>
      </Box>
      {detailview || isPaymentAllowed || (!orderDetails && !openPhone) ? (
        
        <div
          style={{ display: "grid", placeItems: "center", marginBottom: "10px" }}
        >
          <Box>
            <Button
              onClick={() => {
                if (paytimer) clearInterval(paytimer);
                sessionStorage.removeItem("newOrder");
                clearOrder(dispatch);
                props.history.push("/?" + window.location.href.split("?")[1]);
                window.location.reload();
              }}
              variant="contained"
              color="primary"
              style={{fontSize:"1.5em"}}
              className={styles.orderAgainBtn}
            >
             {t({ id: "order_again" })} 
            </Button>
          </Box>
        </div>
      ) : (
        ""
      )}
    </Box>
  );
}
