import React, { useContext, useState } from 'react';
import { Store } from '../Store';

import { Box, Card, CardActionArea, Typography, Select, MenuItem } from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { useStyles } from '../styles';
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import messages from '../assets/locales/index'; 

import Logo from '../components/Logo';
import config,{getParameterByName,merchantCode} from '../Configs'


export default function HomeScreen(props) {
  const { state } = useContext(Store);
  const { formatMessage: t, locale, setLocale } = useIntl(); 

let {userInfo}= state.userData;
  let {loading,setting,error} = state.userSetting;
  const [language, setLanguage] = useState(locale);

  console.log(userInfo);

  const themeColor = userInfo?.themeColor || '#ffbc01';  
  const themeTxtColor = userInfo?.themeTxtColor || '#000'; 
    
   if(userInfo){
     let body = document.getElementsByTagName("body");
    body[0].style.backgroundImage = `url(${userInfo.sokBGImg})`;
    body[0].style.backgroundPosition= "center";
    body[0].style.backgroundSize= "cover";
    body[0].style.backgroundRepeat="no-repeat";
   }


  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem('locale',selectedLanguage);
    window.location.reload();
    //setLanguage(selectedLanguage);
    //setLocale(selectedLanguage);
  };



  const isScan = getParameterByName("isScan");
  const restName = getParameterByName("upiName");

  
  const styles = useStyles();
  return (
    <div id="crd" >
      <div >
        <Box className={[styles.root, styles.red]} >
          <Box id='box' className={[styles.main, styles.center]}>
            
            {/* language dropdown  */}
          <Box style={{ position: 'absolute', bottom: 20, right: 20 }} className="lang-select">
              <Select
                value={language}
                onChange={handleLanguageChange}
                variant="outlined"
                style={{ backgroundColor: '#fff' }}
              >
                <MenuItem value="en">English-English</MenuItem>
                <MenuItem value="ch">German-GER</MenuItem>
                <MenuItem value="hi">Hindi-हिन्दी</MenuItem>
                <MenuItem value="mar">Marathi-मराठी</MenuItem>
                <MenuItem value="kan">Kanada</MenuItem>
               
                

              </Select>
            </Box>

              <Typography id="name" style={{ fontSize: "25px", color: themeTxtColor }}>
              {userInfo?userInfo.customer:""}
            </Typography>
            
                <div id="imgdiv" >
              <div className='logo'>
                             {userInfo&&userInfo.logoImg ? 
                             <img src={userInfo.logoImg} style={{height:"80px",width:"auto"}}/> 
                               : <h1></h1>}
                               </div>
                 </div>
            <Typography className={[styles.bold, styles.title3,  ]} variant="h3" component="h3" id='title' >
                  {t({ id: "welcome_msg" })} 
            </Typography>

            <Box className='choose_card' style={{ display: "flex", justifyContent: "space-evenly", width: "100%", alignItems: "center",padding:"10px" }}>
              <div id="card1" onClick={() => props.history.push('/choose?' + window.location.href.split('?')[1])} style={{ color: themeTxtColor,padding:"20px",  backgroundColor: themeColor, borderRadius: "56px",display: "flex",flexDirection:"column",justifyContent:"space-evenly" ,alignItems:"center"}}>
                <img src="images/Touch.png"alt="BigCo Inc. logo" id="img_card1" style={{ width: "250px",height:"250px"}} />
            <span id="txt-card1"style={{ color: themeTxtColor, fontFamily:"Arial", fontSize : "48px",fontWeight:"400" }}>{t({ id: "touch_here" })} </span>

              </div>
        
              {false && <div className='order_qrcode ' id='card2' style={{color: themeTxtColor,backgroundColor: themeColor,padding:"27px",  borderRadius: "56px",display: "flex",flexDirection:"column",justifyContent:"space-evenly" ,alignItems:"center" }}>
                <QRCode
                  size={456}
                  id='img_card2'
                  style={{width: "250px",height:"250px"}}
                  value={window.location.href.indexOf('isScan')==-1?window.location.href+'&isScan=true':window.location.href}
                  // viewBox={`0 0 456 456`}
                />
                <span id="txt-card2"style={{marginTop:"10px", color: themeTxtColor, fontFamily:"Arial", fontSize : "36px",fontWeight:"400",lineHeight:"1" }}>{t({ id: "scan_qr" })}</span>

              </div>}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

{/* <Box className={[styles.main, styles.center]}>

<Typography className={[styles.bold, styles.title3]} variant="h1" component="h1" id='title'
 }
>
  WELCOME! <br />
  ORDER <br />HERE <br/>

  <TouchAppIcon fontSize="large" ></TouchAppIcon>
</Typography><br /><br />

</Box>
<Box className={styles.center}>
<div>
  <h4 style={{color:"#7e2b2b"}}>Order From Mobile</h4>
  <QRCode
    size={456}
    style={{ height: "100px", maxWidth: "60%", width: "60%" ,marginBottom:"50px" }}
    value={window.location.href}
    viewBox={`0 0 456 456`}
  />
</div>
</Box> */}