import React, { useContext } from "react";
import config, { getParameterByName, merchantCode } from "../Configs";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../styles";
import Logo from "../components/Logo";
import { Store } from "../Store";
import { setOrderType } from "../actions";
import { useIntl } from "react-intl";

export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);
  const { formatMessage: t, locale, setLocale } = useIntl();
  const styles = useStyles();
  let { userInfo } = state.userData;
  let { setting } = state.userSetting;
  console.log(setting);
  const themeColor = userInfo?.themeColor || '#ffbc01';  
  const themeTxtColor = userInfo?.themeTxtColor || '#000'; 

  if (setting) {
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
  }

  const chooseHandler = (orderType) => {
    setOrderType(dispatch, orderType);
    props.history.push("/order?" + window.location.href.split("?")[1]);
  };

  const isScan = getParameterByName("isScan");

  return (
    <>
      <Fade in={true}>
        <Box
          id="contmain"
          className={[styles.root, styles.navy]}
          style={{ backgroundColor: state.selectedBgColor }}
        >
          <Box id="box" className={[styles.main, styles.center]}>
            {/* <img src={state.selectedLogo} height='90px' width='120px' /> */}
            <div id="backlogo">
              {isScan !== "true" ? (
                <div id="imgback">
                  <span
                    onClick={() => {
                      props.history.push(
                        `/?` + window.location.href.split("?")[1]
                      );
                    }}
                    style={{ color: "#000" }}
                  >
                    <img
                      src="images/back-btn.png"
                      style={{
                        width: "35px",
                        height: "35px",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    />
                  </span>
                </div>
              ) : (
                ""
              )}

              <div id="imgdiv">
                <div className="logo">
                  {userInfo && userInfo.logoImg ? (
                    <img
                      src={userInfo.logoImg}
                      style={{ height: "50px", width: "auto" }}
                    />
                  ) : (
                    <h1></h1>
                  )}
                </div>
              </div>
            </div>
            <Typography className={[styles.bold, styles.title3,  ]} variant="h3" component="h3" id='title' >
                  {t({ id: "welcome_msg" })} 
            </Typography>
            <Box className={styles.cards} id="choose_cards" style={{width:'100%'}}>
              <Card
                className="eat_in"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  backgroundColor: themeColor,
                  height: "auto",
                }}
              >
                <div
                  onClick={() => chooseHandler("Eat In")}
                  id="eatbtn"
                  style={{
                    height: "auto",
                   backgroundColor: themeColor,
                    borderRadius: "56px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    padding:"10px",
                    color: themeTxtColor
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Eat In"
                    image="/images/eatin.png"
                    className={styles.media}
                    id="img_eatin"
                    style={{ width: "250px", height: "259px" }}
                  />
                  <CardContent id="txt" style={{paddingBottom:"5px"}}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      color="textPrimary"
                      component="p"
                      style={{ color: themeTxtColor, fontSize:"3.0em",lineHeight:1}}
                    >
                       {t({ id: "eat_here" })}
                    </Typography>
                  </CardContent>
                </div>
              </Card>

              <Card
                id="take-out"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <CardActionArea
                  className="take_out"
                  onClick={() => chooseHandler("Take Away")}
                  style={{
                    height: "auto",
                   backgroundColor: themeColor,
                     borderRadius: "56px !important",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Take Away"
                    image="/images/takeout.png"
                    
                    className={styles.media}
                    id="img-takeout"
                    style={{ width: "250px", height: "259px",backgroundColor: themeColor }}
                  />
                  <CardContent id="txt">
                    <Typography
                      gutterBottom
                      variant="h4"
                      color="textPrimary"
                      component="p"
                      className="para"
                      style={{ color: themeTxtColor, fontSize:"3.0em",lineHeight:1}}
                    >
                     {t({ id: "take_away" })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              {false && isScan === "true" ? (
                <Card
                  id="delivery"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "300px",
                    height: "424px",
                  }}
                >
                  <CardActionArea
                    className="take_out"
                    onClick={() => chooseHandler("Delivery")}
                    style={{
                      width: "300px",
                      height: "424px",
                      backgroundColor: "#F9E0A0 !important",
                      borderRadius: "56px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="Take Away"
                      image="/images/takeout.png"
                      className={styles.media}
                      id="img-takeout"
                      style={{ width: "230px", height: "255px" }}
                    />
                    {false &&<CardContent id="txt">
                      <Typography
                        gutterBottom
                        variant="h4"
                        color="textPrimary"
                        component="p"
                        className="para"
                      >
                        Delivery
                      </Typography>
                    </CardContent>}
                  </CardActionArea>
                </Card>
              ) : (
                " "
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
}

{
  /* <button onClick={() => {
  props.history.push(`/?` + window.location.href.split('?')[1]);
}} className='btn btn-dark ms-4 mb-4' style={{width:"80px",textAlign:"center"}}>
  BACK
</button> */
}
